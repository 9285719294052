import { ReactNode, useState } from 'react';
import Link from 'next/link';
import { Checkbox } from '@mui/material';
import { Post } from 'lib/types';
import { buildPostPath } from 'lib/helpers';
import { PostAccount } from 'components/PostAccount';
import { PostMetrics } from 'components/PostMetrics';
import { CreditsPreview } from 'components/CreditsPreview';
import { trackClickPostCardEvent, trackClickAddFavoritePost, trackClickSignupEvent } from 'lib/hooks/useEventTracking';
import { useApi } from 'lib/contexts/ApplicationState';
import { PostCardClickContext } from 'lib/types/events';
import StarIcon from '@mui/icons-material/Star';
import StarTwoToneIcon from '@mui/icons-material/StarTwoTone';
import { MobileTooltip } from 'components/MobileTooltip';
import { useSnackbar } from 'notistack';
import { useSignUpModal } from 'components/SignUpModal';
import { PinPost } from './PinPost';
import { PostCardMenu } from './PostCardMenu';
import { MatchMetrics } from './MatchMetrics';
import { MatchHeader } from './MatchHeader';
import { PostCardMedia } from './PostCardMedia';

interface Props {
  post: Post;
  includeMetrics: boolean;
  includeCreditPreview: boolean;
  isProfileOwner?: boolean;
  showPin?: boolean;
  fetchData?: () => void;
  mode?: string;
  selected?: boolean;
  onSelect?: () => void;
  moreMenu?: ReactNode;
  showMatchMetrics?: boolean;
  mediaClassName?: string;
  showFavorite?: boolean;
  /** The context is used with event tracking to keep track of where the card was clicked */
  context?: PostCardClickContext;
  upperRightOverlay?: ReactNode;
  /** Usually we don't link to the post page in edit mode, but set this to allow it */
  allowClicksInEditMode?: boolean;
  /** Use fixedSize to force the media to crop to a certain size */
  fixedSize?: { width: number; height: number };
  /** Callback to ignore a suggested credit. This will simply hide the post from the parent state */
  onIgnoreSuggestedCredit?: (postId: number) => void;
}

export const PostCard = ({
  post,
  includeMetrics,
  includeCreditPreview,
  isProfileOwner,
  showPin,
  fetchData,
  mode = 'view',
  selected = false,
  onSelect,
  moreMenu,
  showMatchMetrics,
  mediaClassName,
  context,
  upperRightOverlay,
  showFavorite = false,
  allowClicksInEditMode = false,
  fixedSize,
  onIgnoreSuggestedCredit,
}: Props) => {
  const postAccount = post.account;
  const postDetailHref = buildPostPath(post);
  const isEditMode = mode.includes('edit');
  const API = useApi();
  const [isFavorited, setIsFavorited] = useState(post.isFavorited);
  const { enqueueSnackbar } = useSnackbar();
  const { showSignUpModal } = useSignUpModal();

  const onClick = () => {
    trackClickPostCardEvent(API, post.id, context);
  };

  const handleFavoritePost = async () => {
    if (!isFavorited) {
      await trackClickAddFavoritePost(API, post.id, 'post-card');
    }

    if (!post.favoritesListId) {
      trackClickSignupEvent(API, 'post-card-add-favorite');
      showSignUpModal('Sign in to save your favorite posts!');
      return;
    }

    try {
      if (isFavorited) {
        await API.removeItemFromList(post.favoritesListId, post.id);
      } else {
        await API.addPostToList(post.favoritesListId, post.id);
      }
      setIsFavorited(!isFavorited);
    } catch (err) {
      enqueueSnackbar('An error occurred while trying to favorite this post');
    }
  };

  return (
    <div
      className={`bg-white rounded-md sm:max-w-xs relative ${post.isFeatured ? 'border-2 border-gold' : 'border border-lightgray'} ${selected && 'border-2 border-orange'}`}
    >
      <div className="grid grid-cols-6 justify-between items-center py-1">
        <span className={`col-span-5 flex items-center ${!isEditMode && 'pl-2'}`}>
          {isEditMode
            && <Checkbox checked={selected} onChange={() => onSelect && onSelect()} />}
          {post.networks
            ? (
              <MatchHeader
                account={postAccount}
                networks={post.networks}
                matchCount={post.matchCount || 0}
              />
            )
            : (
              <PostAccount
                size="medium"
                post={post}
                {...(mode.includes('edit') && { noLink: true })}
              />
            )}
        </span>
        <span className="z-10">
          <PostCardMenu
            post={post}
            showPin={showPin}
            fetchData={fetchData}
            isProfileOwner={isProfileOwner}
          >
            {moreMenu}
          </PostCardMenu>
        </span>
      </div>
      <div className="relative">
        {showPin && (
          <PinPost
            postId={post.id}
            isProfileOwner={isProfileOwner}
            onSuccess={fetchData}
          />
        )}
        {!!upperRightOverlay && (
          <div className="absolute top-1 right-1">{upperRightOverlay}</div>
        )}
        {showFavorite && (
          <MobileTooltip title={`${isFavorited ? 'Remove post from' : 'Add post to'} favorites`}>
            <button type="button" className="absolute top-1 left-1 z-25" aria-label="favorite-post" onClick={handleFavoritePost}>
              {isFavorited ? <StarIcon color="primary" fontSize="large" /> : <StarTwoToneIcon color="primary" fontSize="large" />}
            </button>
          </MobileTooltip>
        )}
        {isEditMode && !allowClicksInEditMode ? <PostCardMedia post={post} fixedSize={fixedSize} />
          : (
            <Link
              href={{ pathname: buildPostPath(post) }}
              as={postDetailHref}
              passHref
              onClick={onClick}
            >
              <PostCardMedia post={post} className={mediaClassName} fixedSize={fixedSize} />
            </Link>
          )}
      </div>
      {showMatchMetrics && (
        <MatchMetrics
          likes={post.matchLikes}
          shares={post.matchShares}
          combinedViews={post.matchCombinedViews}
          comments={post.matchComments}
        />
      )}
      {includeMetrics && <PostMetrics post={post} decimals={0} isPostCard />}
      {includeCreditPreview
        && (
          <CreditsPreview
            postId={post.id}
            credits={post.credits}
            path={buildPostPath(post)}
            onIgnoreSuggestedCredit={onIgnoreSuggestedCredit}
          />
        )}
    </div>
  );
};
